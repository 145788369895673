const Clinic = () => {
  return (
    <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00016 1.33333V2.66667H12.3335C13.0668 2.66667 13.6668 3.26667 13.6668 4V10.6667C13.6668 11.4 13.0668 12 12.3335 12H1.66683C0.933496 12 0.333496 11.4 0.333496 10.6667V1.33333C0.333496 0.6 0.933496 0 1.66683 0H5.66683C6.40016 0 7.00016 0.6 7.00016 1.33333ZM1.66682 10.6667H3.00015V9.3334H1.66682V10.6667ZM3.00015 8.00002H1.66682V6.66669H3.00015V8.00002ZM1.66682 5.33334H3.00015V4.00001H1.66682V5.33334ZM3.00015 2.66662H1.66682V1.33329H3.00015V2.66662ZM4.33351 10.6667H5.66684V9.3334H4.33351V10.6667ZM5.66684 8.00002H4.33351V6.66669H5.66684V8.00002ZM4.33351 5.33334H5.66684V4.00001H4.33351V5.33334ZM5.66684 2.66662H4.33351V1.33329H5.66684V2.66662ZM7.00016 10.6667H11.6668C12.0335 10.6667 12.3335 10.3667 12.3335 10V4.66668C12.3335 4.30001 12.0335 4.00001 11.6668 4.00001H7.00016V5.33334H8.3335V6.66668H7.00016V8.00001H8.3335V9.33334H7.00016V10.6667ZM11.0002 5.33331H9.66683V6.66664H11.0002V5.33331ZM9.66683 7.99999H11.0002V9.33332H9.66683V7.99999Z'
        fill='#831A4A'
      />
    </svg>
  );
};

export default Clinic;
