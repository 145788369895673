const AboutWave = () => {
  return (
    <svg viewBox='0 0 1280 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        transform='translate(0,2)'
        d='M0 34.2825V64.2334H1280V8.49152C1280 8.49152 1084.58 43.5883 914.643 43.5883C744.709 43.5883 533.792 0.233398 363.858 0.233398C193.924 0.233398 0 34.2825 0 34.2825Z'
        fill='#831A4A'
      />
    </svg>
  );
};
export default AboutWave;
