const Virtual = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.66667 4.66699C2.66667 4.30033 2.96667 4.00033 3.33333 4.00033H14C14.3667 4.00033 14.6667 3.70033 14.6667 3.33366C14.6667 2.96699 14.3667 2.66699 14 2.66699H2.66667C1.93333 2.66699 1.33333 3.26699 1.33333 4.00033V11.3337H1C0.446667 11.3337 0 11.7803 0 12.3337C0 12.887 0.446667 13.3337 1 13.3337H8.33333C8.88667 13.3337 9.33333 12.887 9.33333 12.3337C9.33333 11.7803 8.88667 11.3337 8.33333 11.3337H2.66667V4.66699ZM15.3333 5.33372H11.3333C10.9667 5.33372 10.6667 5.63372 10.6667 6.00039V12.6671C10.6667 13.0337 10.9667 13.3337 11.3333 13.3337H15.3333C15.7 13.3337 16 13.0337 16 12.6671V6.00039C16 5.63372 15.7 5.33372 15.3333 5.33372ZM12 11.3337H14.6667V6.66702H12V11.3337Z'
        fill='#831A4A'
      />
      <path
        d='M6.66683 5.33301C7.40016 5.33301 8.00016 5.93301 8.00016 6.66634C8.00016 7.39968 7.40016 7.99968 6.66683 7.99968C5.9335 7.99968 5.3335 7.39968 5.3335 6.66634C5.3335 5.93301 5.9335 5.33301 6.66683 5.33301Z'
        fill='#831A4A'
      />
      <path
        d='M9.33333 10.667H4V10.0003C4 9.11366 5.78 8.66699 6.66667 8.66699C7.55333 8.66699 9.33333 9.11366 9.33333 10.0003V10.667Z'
        fill='#831A4A'
      />
    </svg>
  );
};

export default Virtual;
