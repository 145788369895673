import { useEffect, useRef } from "react";
import talk from "../../assets/images/talk.png";
import CloseIcon from "../../UI/Svgs/CloseIcon";
import styles from "./styles.module.css";
import Button from "../../UI/Button";

const AboutModal = ({ visible, onClose }: { visible: boolean; onClose: () => void }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isMobile = /Android|iPhone/i.test(navigator.userAgent);

  function useOutsideAlerter(ref: { current: any }) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  return visible ? (
    <div className={styles.modalWrapper} ref={wrapperRef}>
      <div className={styles.close}>
        <span onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={styles.modalContent}>
        <div>
          <div className={styles.title}>
            <span>At Tia, it all starts with you</span>
          </div>
          <div className={styles.modalDesc}>
            <span>
              At Tia, we believe that women’s health isn&apos;t purely physical,
              {!isMobile && <br />} it’s mental and emotional. So we go beyond body parts{" "}
              {!isMobile && <br />} and believe in gaining a more complete picture of your{" "}
              {!isMobile && <br />} whole health. Finally, healthcare that treats the whole you.
            </span>
          </div>
          <div className={styles.modalButton}>
            <a href='https://asktia.com/' target='_blank' rel='noreferrer noopener'>
              <Button onClick={() => null} bg='bg-primary-3' color='tertiary-4' bordered>
                Discover a new standard of care
              </Button>
            </a>
          </div>
        </div>
        <div>
          <img src={talk} width='100%' height='100%' alt='' />
        </div>
      </div>
    </div>
  ) : null;
};

export default AboutModal;
