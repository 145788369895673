const MantraWave = () => {
  return (
    <svg viewBox='0 0 1280 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1280 14.806V32L640 32L0 32L0 0C0 0 195.424 14.806 365.357 14.806C535.291 14.806 746.208 0 916.142 0C1086.08 0 1280 14.806 1280 14.806Z'
        fill='#FCF4E9'
      />
    </svg>
  );
};

export default MantraWave;
