import { ReactElement, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import cc from "classcat";
import {
  Apple,
  ArrowDown,
  Ball,
  Book,
  Bulb,
  Candle,
  Chromo,
  Cloud,
  Coffee,
  Garlic,
  Hand,
  Heart,
  ManHead,
  Meditation,
  Moon,
  Nap,
  Up
} from "../../UI/Svgs";
import Button from "../../UI/Button";
import Input from "../../UI/Input";
import Tag from "../../UI/Tag";
import styles from "./styles.module.css";
import Header from "../../components/Header";
import Carousel from "../../components/Carousel";
import AboutModal from "../../components/AboutModal";
import { answerShortList, answerLongList, fifthAnswers, fifthAnswersSecond } from "../../constants";
import itly from "../../itly";
import { getUser, logCustomEvent } from "@braze/web-sdk";

const Home = (): ReactElement => {
  const [step, setStep] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [required, setRequired] = useState(false);
  const [thirdAnswers, setThirdAnswers] = useState<string[]>(
    Object.keys(!/Android|iPhone/i.test(navigator.userAgent) ? answerLongList : answerShortList)
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [forthSelected, setForthSelected] = useState("");
  const [fifthSelectedItem, setFifthSelectedItem] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const firstRef = useRef<HTMLHeadingElement>(null);
  const secondRef = useRef<HTMLHeadingElement>(null);
  const thirdRef = useRef<HTMLHeadingElement>(null);
  const forthRef = useRef<HTMLHeadingElement>(null);
  const results = useRef<HTMLHeadingElement>(null);
  const navigate = useNavigate();

  const isMobile = /Android|iPhone/i.test(navigator.userAgent);

  const changeFirstName = useCallback(
    (value: string) => {
      if (required) {
        setRequired(false);
      }
      localStorage.setItem("firstName", value);
      setFirstName(value);
    },
    [required]
  );
  const changeLastName = useCallback(
    (value: string) => {
      if (required) {
        setRequired(false);
      }
      localStorage.setItem("lastName", value);
      setLastName(value);
    },
    [required]
  );
  const changeEmail = useCallback(
    (value: string) => {
      if (required) {
        setRequired(false);
      }
      localStorage.setItem("email", value);
      setEmail(value);
    },
    [required]
  );

  const manifestDisabled = useMemo(
    () => !firstName || !lastName || !email || !/^(.+)@(.+)$/.test(email),
    [email, firstName, lastName]
  );

  const handleGoNext = useCallback(() => {
    if (manifestDisabled) {
      setRequired(true);
    } else {
      if (step < 1) {
        setStep(1);
        localStorage.setItem("step", "1");
      }
      setRequired(false);
      firstRef.current?.scrollIntoView({ behavior: "smooth" });
      itly.emailCaptured({ email });
      getUser()?.addAlias(email, "email");
      getUser()?.setEmail(email);
      getUser()?.setFirstName(firstName);
      getUser()?.setLastName(lastName);
      logCustomEvent("Email Captured", { email });
      logCustomEvent("Self Health Started", { selfHealthStarted: true });
      itly.selfHealthStarted({ selfHealthStarted: true });
    }
  }, [email, firstName, lastName, manifestDisabled, step]);

  const handleGoSecond = useCallback(() => {
    if (step < 2) {
      setStep(2);
      localStorage.setItem("step", "2");
    }
    secondRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [step]);

  const handleShowMoreAnswers = useCallback(() => {
    if (thirdAnswers.length === Object.keys(answerShortList).length) {
      setThirdAnswers(Object.keys(answerLongList));
    } else {
      setThirdAnswers(Object.keys(answerShortList));
    }
  }, [thirdAnswers.length]);

  const handleGoThird = useCallback(() => {
    if (step < 3) {
      setStep(3);
      localStorage.setItem("step", "3");
    }
    thirdRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [step]);

  const handleGoForth = useCallback(() => {
    if (step < 4) {
      setStep(4);
      localStorage.setItem("step", "4");
    }
    forthRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [step]);

  const handleGoResults = useCallback(() => {
    setStep(5);
    localStorage.removeItem("step");
    navigate("/results");
    logCustomEvent("Self Health Completed", { FocusArea: selectedItem });
    itly.selfHealthCompleted({ FocusArea: selectedItem });
  }, [navigate, selectedItem]);

  const handleAnswerClick = useCallback((item: string) => {
    setSelectedItem(item);
    setForthSelected("");
    localStorage.setItem("third", item);
  }, []);

  const handleDeselectAnswer = useCallback(() => {
    setSelectedItem("");
    localStorage.removeItem("third");
  }, []);

  const handleForthQuestionSelect = useCallback((value: string) => {
    setForthSelected(value);
    localStorage.setItem("forth", value);
  }, []);

  const handleFifthAnswerClick = useCallback((item: string) => {
    setFifthSelectedItem(item);
    localStorage.setItem("fifth", item);
  }, []);

  const handleFifthDeselectAnswer = useCallback(() => {
    setFifthSelectedItem("");
  }, []);

  const handleAbout = useCallback((value: boolean) => {
    setPopupVisible(value);
  }, []);

  useEffect(() => {
    switch (step) {
      case 1:
        firstRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        secondRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        thirdRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        forthRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 5:
        results.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  }, [step]);

  useEffect(() => {
    const firstName = localStorage.getItem("firstName") || "";
    const lastName = localStorage.getItem("lastName") || "";
    const email = localStorage.getItem("email") || "";
    const third = localStorage.getItem("third") || "";
    const forth = localStorage.getItem("forth") || "";
    const fifth = localStorage.getItem("fifth") || "";
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setSelectedItem(third);
    setForthSelected(forth);
    setFifthSelectedItem(fifth);
    const currentStep = localStorage.getItem("step") || 0;
    setStep(+currentStep);
  }, []);

  return (
    <>
      <Header titleVisible={step !== 0} handleAbout={() => handleAbout(true)} />
      <div className={styles.pageWrapper}>
        {step < 1 && (
          <div className={styles.pageWrapperInner}>
            <div className={styles.headerWrapper}>
              <div className={styles.titleWrapper}>
                <span className={styles.title}>Self </span>
                <span className={styles.self}>Health </span>
                <span className={styles.title}>Generator</span>
              </div>
              <div className={styles.subHeaderWrapper}>
                <span className={styles.subHeader}>Turn your resolutions into reality</span>
              </div>
              <div className={styles.descWrapper}>
                <span className={styles.description}>
                  Take this first step and go beyond self help and empty resolutions.{" "}
                  {!isMobile && <br />} Generate a clear, personalized guide to your healthiest year
                  yet with {!isMobile && <br />} science-backed support from our providers. Then,
                  turn your 2023 {!isMobile && <br />} goals into intentional habits.
                </span>
              </div>
              <Carousel />
              <div>
                <div
                  className={cc([
                    styles.firstInputGroup,
                    {
                      [styles.inputsMobile]: isMobile
                    }
                  ])}>
                  <div className={cc([{ [styles.inputError]: required && !firstName }])}>
                    <div className={styles.firstLabelGroup}>
                      <span>First Name</span>
                    </div>
                    <Input placeholder='First Name' value={firstName} onChange={changeFirstName} />
                    <div className={styles.error}>
                      {required && !firstName && <span>Field is required</span>}
                    </div>
                  </div>
                  <div className={cc([{ [styles.inputError]: required && !lastName }])}>
                    <div className={styles.firstLabelGroup}>
                      {" "}
                      <span>Last Name</span>
                    </div>
                    <Input placeholder='Last Name' value={lastName} onChange={changeLastName} />
                    <div className={styles.error}>
                      {required && !lastName && <span>Field is required</span>}
                    </div>
                  </div>
                </div>
                <div className={styles.secondLabelGroup}>
                  <span>Email Address</span>
                </div>
                <div className={styles.secondInputGroup}>
                  <div
                    className={cc([
                      { [styles.inputError]: required && !/^(.+)@(.+)$/.test(email) }
                    ])}>
                    <Input
                      placeholder='Email Address'
                      value={email}
                      onChange={changeEmail}
                      type='email'
                      pattern='^(.+)@(.+)$'
                    />
                    <div className={styles.error}>
                      {required && !/^(.+)@(.+)$/.test(email) && (
                        <span>Enter valid email address please</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.nextWrapper}>
                <Button onClick={handleGoNext}>Generate your 2023 Self Health Guide</Button>
              </div>
              <div className={styles.terms}>
                <span className={styles.termsInner}>
                  By selecting ‘<b>Generate your 2023 Self Health Guide</b>’ you agree to our{" "}
                  <a
                    href='https://asktia.com/terms-of-use'
                    target='_blank'
                    rel='noreferrer noopener'>
                    Terms of Use
                  </a>{" "}
                  &{" "}
                  <a
                    href='https://www.asktia.com/privacy-policy'
                    target='_blank'
                    rel='noreferrer noopener'>
                    Privacy Policy
                  </a>{" "}
                  {!isMobile && <br />}
                  and to receive emails from Tia.
                </span>
              </div>
            </div>
            {!isMobile && (
              <span className={styles.landingMan}>
                <ManHead width='112' height='134' />
              </span>
            )}
            {!isMobile && (
              <span className={styles.landingBall}>
                <Ball />
              </span>
            )}
            {!isMobile && (
              <span className={styles.landingHand}>
                <Hand />
              </span>
            )}
            {!isMobile && (
              <span className={styles.landingApple}>
                <Apple />
              </span>
            )}
            {!isMobile && (
              <span className={styles.landingBook}>
                <Book />
              </span>
            )}
          </div>
        )}
        {!manifestDisabled && step > 0 && (
          <div className={styles.pageWrapperInner} ref={firstRef}>
            <div className={styles.secondItem}>
              <div className={styles.secondItemTitle}>
                <span>
                  See it, believe it, <i style={{ fontFamily: "inferi-normal-italic" }}> feel</i>{" "}
                  it.
                </span>
              </div>
              <div className={styles.secondItemIntro}>
                <span>Hey {firstName}, we’d love to learn more about the whole you.</span>
              </div>
              <div className={styles.secondButton}>
                <Button onClick={handleGoSecond}>
                  <ArrowDown />
                </Button>
              </div>
            </div>
            {!isMobile && (
              <span className={styles.secondMoon}>
                <Moon />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondChromo}>
                <Chromo />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondCloud}>
                <Cloud width='145' height='83' />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondMeditation}>
                <Meditation width='150' height='120' />
              </span>
            )}
          </div>
        )}
        {step > 1 && (
          <div className={styles.pageWrapperInner} ref={secondRef}>
            <div className={styles.thirdItem}>
              <div className={styles.thirdItemIntro}>
                <span>
                  We’re always growing, changing. Choose one area that {!isMobile && <br />} you’d
                  like to focus on in 2023…
                </span>
              </div>
              <div className={styles.tagsWrapper}>
                {thirdAnswers.map((value, index) => {
                  return (
                    <Tag
                      key={index}
                      value={value}
                      selected={selectedItem === value}
                      onClick={(e: { preventDefault: () => void }) => {
                        e.preventDefault();
                        handleAnswerClick(value);
                      }}
                      onDeselect={handleDeselectAnswer}
                    />
                  );
                })}
              </div>
              <div className={styles.showMore}>
                {thirdAnswers.length === Object.keys(answerShortList).length && isMobile && (
                  <Button onClick={handleShowMoreAnswers} outlined>
                    Show more
                  </Button>
                )}
              </div>
              <div className={cc([styles.thirdButton, styles.mobileThird])}>
                <Button onClick={handleGoNext} outlined>
                  Back
                </Button>
                <Button onClick={handleGoThird} disabled={!selectedItem}>
                  Next
                </Button>
              </div>
            </div>
            {!isMobile && (
              <span className={styles.secondMoon}>
                <Bulb width='145' height='83' />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondChromo}>
                <Coffee width='145' height='83' black />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondCloud}>
                <Heart width='145' height='83' black />
              </span>
            )}
            {!isMobile && (
              <span className={styles.secondMeditation}>
                <Nap width='145' height='83' />
              </span>
            )}
          </div>
        )}
        {step > 2 && (
          <div className={styles.pageWrapperInner} ref={thirdRef}>
            <div className={styles.forthItem}>
              <div className={styles.forthItemIntro}>
                <span>
                  You chose <b>{selectedItem.toLowerCase()}</b>, why?
                </span>
              </div>
              <div className={styles.forthOptions}>
                {answerLongList[selectedItem]?.map((value: string, index: number) => {
                  return (
                    <span
                      onClick={() => handleForthQuestionSelect(value)}
                      key={index}
                      className={cc([
                        styles.forthOption,
                        {
                          [styles.selectedOption]: forthSelected === value
                        }
                      ])}>
                      {value}
                    </span>
                  );
                })}
              </div>
              <div className={styles.thirdButton}>
                <Button onClick={handleGoSecond} outlined>
                  Back
                </Button>
                <Button onClick={handleGoForth} disabled={!forthSelected}>
                  Next
                </Button>
              </div>
            </div>
            {!isMobile && (
              <span className={styles.forthCandle}>
                <Candle />
              </span>
            )}
            {!isMobile && (
              <span className={styles.forthMan}>
                <ManHead width='112' height='134' />
              </span>
            )}
            {!isMobile && (
              <span className={styles.forthUp}>
                <Up width='112' height='134' />
              </span>
            )}
          </div>
        )}
        {step > 3 && (
          <div className={styles.pageWrapperInner} ref={forthRef}>
            <div className={styles.thirdItem}>
              <div className={styles.thirdItemIntro}>
                <span>
                  In 2022 we felt ALL the feels. What is one thing you <br /> would like to feel
                  more of in 2023?
                </span>
              </div>
              <div className={styles.tagsWrapper}>
                {fifthAnswers.map((value, index) => {
                  return (
                    <Tag
                      key={index}
                      value={value}
                      selected={fifthSelectedItem === value}
                      onClick={(e: { preventDefault: () => void }) => {
                        e.preventDefault();
                        handleFifthAnswerClick(value);
                      }}
                      onDeselect={handleFifthDeselectAnswer}
                    />
                  );
                })}
              </div>
              <div className={styles.tagsWrapper}>
                {fifthAnswersSecond.map((value, index) => {
                  return (
                    <Tag
                      key={index}
                      value={value}
                      selected={fifthSelectedItem === value}
                      onClick={(e: { preventDefault: () => void }) => {
                        e.preventDefault();
                        handleFifthAnswerClick(value);
                      }}
                      onDeselect={handleFifthDeselectAnswer}
                    />
                  );
                })}
              </div>
              <div className={styles.thirdButton}>
                <Button onClick={handleGoThird} outlined>
                  Back
                </Button>
                <Button onClick={handleGoResults} disabled={!fifthSelectedItem}>
                  Next
                </Button>
              </div>
            </div>
            {!isMobile && (
              <span className={styles.landingBall}>
                <Ball />
              </span>
            )}
            {!isMobile && (
              <span className={styles.landingHand}>
                <Book />
              </span>
            )}
            {!isMobile && (
              <span className={styles.forthMan}>
                <Nap width='145' height='83' />
              </span>
            )}
            {!isMobile && (
              <span className={styles.forthUp}>
                <Garlic />
              </span>
            )}
          </div>
        )}
      </div>
      {createPortal(
        <AboutModal visible={popupVisible} onClose={() => handleAbout(false)} />,
        document.body
      )}
    </>
  );
};

export default Home;
