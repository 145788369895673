import styles from "./styles.module.css";

const Input = ({
  placeholder,
  value,
  onChange,
  type = "text",
  pattern = ""
}: {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
  pattern?: string;
}) => {
  return (
    <input
      className={styles.input}
      placeholder={placeholder}
      type={type}
      pattern={pattern}
      defaultValue={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
export default Input;
