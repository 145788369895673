const Link = ({ white, black }: { white?: boolean; black?: boolean }) => {
  return (
    <svg
      fill={black ? "#282725" : white ? "#ffffff" : "#831a4a"}
      width='20'
      height='20'
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M0,0H48V48H0Z' transform='translate(0 0)' fill='none' />
      <g>
        <path
          d='M16,36h4V32H16a8,8,0,0,1,0-16h4V12H16a12,12,0,0,0,0,24Z'
          transform='translate(0 0)'
        />
        <path
          d='M32,12H28v4h4a8,8,0,0,1,0,16H28v4h4a12,12,0,0,0,0-24Z'
          transform='translate(0 0)'
        />
        <rect x='16' y='22' width='16' height='4' />
      </g>
    </svg>
  );
};
export default Link;
