import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import cc from "classcat";
import styles from "./styles.module.css";
import {
  betterHealthColorAnswers,
  creativeColorAnswers,
  futurePlansColorAnswers,
  relationShipColorAnswers
} from "../../constants";
import Logo from "../../UI/Svgs/Logo";
import Button from "../../UI/Button";
import { Link, Menu } from "../../UI/Svgs";

const Header = ({
  titleVisible = true,
  handleAbout
}: {
  titleVisible?: boolean;
  handleAbout: () => void;
}) => {
  const [copy, setCopy] = useState(false);
  const [headerOpen, setHeaderOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const location = useLocation();
  const isMobile = /Android|iPhone/i.test(navigator.userAgent);
  const thirdAnswer = localStorage.getItem("third") || "Mindful Eating";

  const toggleHeader = useCallback(() => {
    setHeaderOpen((prev) => !prev);
  }, []);

  const headerRef = useRef<HTMLDivElement>(null);

  function useOutsideAlerter(ref: { current: any }) {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (headerOpen) {
            toggleHeader();
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, headerOpen]);
  }

  useOutsideAlerter(headerRef);

  const onResultsPage = location.pathname === "/results";

  return (
    <header
      ref={headerRef}
      className={cc([
        styles.header,
        {
          "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer) && onResultsPage,
          "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer) && onResultsPage,
          "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer) && onResultsPage,
          "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer) && onResultsPage
        }
      ])}>
      <div className={styles.headerInner}>
        <span>
          <a href='https://asktia.com/' rel='noreferrer noopener' target='_blank'>
            <Logo
              color={
                relationShipColorAnswers.includes(thirdAnswer) && onResultsPage
                  ? "white"
                  : undefined
              }
            />
          </a>
          {titleVisible && (
            <span
              className={cc([
                styles.title,
                {
                  "tertiary-4": relationShipColorAnswers.includes(thirdAnswer) && onResultsPage
                }
              ])}>
              Self Health Generator
            </span>
          )}
        </span>
        {isMobile ? (
          <div className={styles.menu} onClick={toggleHeader}>
            <Menu white={relationShipColorAnswers.includes(thirdAnswer) && onResultsPage} />
          </div>
        ) : (
          <div
            className={cc([
              styles.rightItems,
              {
                "tertiary-4": relationShipColorAnswers.includes(thirdAnswer) && onResultsPage,
                [styles.rightItemsWhite]:
                  relationShipColorAnswers.includes(thirdAnswer) && onResultsPage
              }
            ])}>
            <a
              style={{ position: "relative" }}
              onClick={() => {
                navigator.clipboard.writeText("https://www.selfhealth.asktia.com");
                setTooltipVisible(true);
                setTimeout(() => {
                  setTooltipVisible(false);
                }, 1000);
              }}
              onMouseEnter={() => setCopy(true)}
              onMouseLeave={() => setCopy(false)}>
              {copy ? (
                <span style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Link white={relationShipColorAnswers.includes(thirdAnswer) && onResultsPage} />
                  Copy link
                </span>
              ) : (
                "Share this generator"
              )}
              {tooltipVisible && <span className={styles.copiedPopup}>Link Copied</span>}
            </a>
            <a onClick={handleAbout}>About</a>
            <a href='https://asktia.com/join/join-tia' target='_blank' rel='noreferrer noopener'>
              <Button
                onClick={() => null}
                bg={
                  relationShipColorAnswers.includes(thirdAnswer) && onResultsPage
                    ? "bg-primary-2"
                    : "bg-primary-3"
                }
                color='tertiary-4'>
                Join Tia
              </Button>
            </a>
          </div>
        )}
      </div>
      {headerOpen && (
        <div className={cc([styles.headerDropdown, "bg-secondary-1"])}>
          <div>
            <a
              onClick={() => {
                toggleHeader();
                handleAbout();
              }}>
              About
            </a>
          </div>
          <div className={styles.mt8}>
            <a
              style={{ position: "relative" }}
              onClick={() => {
                navigator.clipboard.writeText("https://www.selfhealth.asktia.com");
                setTooltipVisible(true);
                setTimeout(() => {
                  setTooltipVisible(false);
                }, 1000);
              }}
              onMouseEnter={() => setCopy(true)}
              onMouseLeave={() => setCopy(false)}>
              {copy ? (
                <span style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Link black />
                  Copy link
                </span>
              ) : (
                "Share this generator"
              )}
              {tooltipVisible && <span className={styles.copiedPopup}>Link Copied</span>}
            </a>
          </div>
          <div className={styles.mt8}>
            <a href='https://asktia.com/join/join-tia' target='_blank' rel='noreferrer noopener'>
              <Button
                onClick={() => null}
                bg={
                  relationShipColorAnswers.includes(thirdAnswer) && onResultsPage
                    ? "bg-primary-2"
                    : "bg-primary-3"
                }
                color='tertiary-4'>
                Join Tia
              </Button>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
