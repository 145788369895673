const Menu = ({ white }: { white?: boolean }) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 8.5C3.45 8.5 3 8.05 3 7.5C3 6.95 3.45 6.5 4 6.5H20C20.55 6.5 21 6.95 21 7.5C21 8.05 20.55 8.5 20 8.5H4ZM4 13.5H20C20.55 13.5 21 13.05 21 12.5C21 11.95 20.55 11.5 20 11.5H4C3.45 11.5 3 11.95 3 12.5C3 13.05 3.45 13.5 4 13.5ZM4 18.5H20C20.55 18.5 21 18.05 21 17.5C21 16.95 20.55 16.5 20 16.5H4C3.45 16.5 3 16.95 3 17.5C3 18.05 3.45 18.5 4 18.5Z'
        fill={white ? "#ffffff" : "#282725"}
      />
    </svg>
  );
};

export default Menu;
