const PlayListWave = () => {
  return (
    <svg viewBox='0 0 1280 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        transform='translate(0,2)'
        d='M1280 34.2825V64.2334H0V8.49152C0 8.49152 195.424 43.5883 365.357 43.5883C535.291 43.5883 746.208 0.233398 916.142 0.233398C1086.08 0.233398 1280 34.2825 1280 34.2825Z'
        fill='#ECDCCD'
      />
    </svg>
  );
};

export default PlayListWave;
