import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ReactElement, useEffect } from "react";
import { RequestLoggerPlugin } from "@itly/sdk";
import { MixpanelPlugin } from "@itly/plugin-mixpanel";
import itly, { Loggers } from "./itly";
import Home from "./pages/Home";
import Results from "./pages/Results";
import styles from "./index.module.css";

const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
  ? process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
  : "";

const BRAZE_WEB_API_KEY = process.env.REACT_APP_BRAZE_WEB_API_KEY
  ? process.env.REACT_APP_BRAZE_WEB_API_KEY
  : "";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "results",
    element: <Results />
  }
]);

const App = (): ReactElement => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      import(
        /* webpackExports: ["initialize", "openSession"] */
        "@braze/web-sdk"
      ).then(({ initialize, openSession }) => {
        initialize(BRAZE_WEB_API_KEY, {
          baseUrl: "https://rest.iad-02.braze.com/"
        });
        openSession();
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && process.env.NODE_ENV !== "development") {
      let plugins: RequestLoggerPlugin[] = [];
      plugins = [
        new MixpanelPlugin(MIXPANEL_PROJECT_TOKEN, {
          cross_subdomain_cookie: true
        })
      ];
      const loadConfig: any = Object.assign(
        {
          plugins,
          environment: process.env.NODE_ENV
        },
        process.env.NODE_ENV && process.env.NODE_ENV === "production"
          ? {}
          : { logger: Loggers.Console }
      );
      itly.load(loadConfig);
    }
  }, []);
  return (
    <div className={styles.appWrapper}>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
