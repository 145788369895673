import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { Autoplay } from "swiper";
import cc from "classcat";
import {
  betterHealthColorAnswers,
  creativeColorAnswers,
  futurePlansColorAnswers,
  relationShipColorAnswers,
  blurb,
  playlistSection,
  author
} from "../../constants";
import wholeHealth from "../../assets/images/wholeHealth.png";
import fertility from "../../assets/images/fertility.png";
import acupuncture from "../../assets/images/acupuncture.png";
import talkTherapy from "../../assets/images/talkTherapy.png";
import { MantraWave, PlayListWave, Logo, Clinic, VirtualClinic, Virtual } from "../../UI/Svgs";
import woman from "../../assets/images/woman.png";
import talk from "../../assets/images/talk.png";
import Header from "../../components/Header";
import styles from "./styles.module.css";
import Button from "../../UI/Button";
import AboutWave from "../../UI/Svgs/AboutWave";

const Results = () => {
  const [imageUrl, setImageUrl] = useState("");
  const aboutRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const thirdAnswer = localStorage.getItem("third") || "Mindful Eating";
  const forthAnswer = localStorage.getItem("forth") || "Mindful Eating";
  const templateAnswer = localStorage.getItem("fifth") || "Energized";

  const isMobile = /Android|iPhone/i.test(navigator.userAgent);

  const appointments: {
    title: string;
    icon: ReactElement;
    place: string;
    image: string;
    content: string;
    link: string;
  }[] = [
    {
      title: "Tia Whole Health Exam",
      icon: <Virtual />,
      place: "Virtual",
      image: wholeHealth,
      content:
        "Your yearly 3-part preventive visit: Start with a virtual appointment to review your health history and develop a Care Plan, come into the clinic for your in-person physical exam (e.g. vitals, pap test, bloodwork), and close the loop with a virtual review of your labs.",
      link: "https://asktia.com/join/join-tia/"
    },
    ...(thirdAnswer !== "Start a family"
      ? [
          {
            title: "Talk Therapy Program",
            icon: <VirtualClinic />,
            place: "Virtual & In-Clinic",
            image: talkTherapy,
            content:
              "Depressed, stressed, anxious, or just want to chat? Virtual talk therapy means you get counseling from your couch with Tia-vetted licensed clinical therapists you can trust.",
            link: "https://asktia.com/join/join-tia/"
          }
        ]
      : []),
    ...(thirdAnswer === "Start a family"
      ? [
          {
            title: "Fertility assessment",
            icon: <VirtualClinic />,
            place: "Virtual & In-Clinic",
            image: fertility,
            content:
              "Curious about your fertility? Get a full overview of your fertility with testing & counseling. Plan for your fertility future in an environment free from upselling.",
            link: "https://asktia.com/join/join-tia/"
          }
        ]
      : []),
    {
      title: "Acupuncture",
      icon: <Clinic />,
      place: "In-Clinic",
      image: acupuncture,
      content:
        "Science-backed traditional Chinese medicine good for the prevention & treatment of stress, headaches, PMS, chronic pain, & more.",
      link: "https://asktia.com/join/join-tia/"
    }
  ];

  const categoryBucket = useCallback(() => {
    if (creativeColorAnswers.includes(thirdAnswer)) {
      return "abundance";
    } else if (relationShipColorAnswers.includes(thirdAnswer)) {
      return "closeness";
    } else if (betterHealthColorAnswers.includes(thirdAnswer)) {
      return "betterment";
    } else {
      return "readiness";
    }
  }, [thirdAnswer]);

  const templates: { [key: string]: { mantra: ReactElement } } = useMemo(() => {
    return {
      Energized: {
        mantra: (
          <span>
            I am buzzing with energy {playlistSection[categoryBucket()].icon1}, nourished{" "}
            {!isMobile && <br />} by balance and rest {playlistSection[categoryBucket()].icon2},
            which gives me {!isMobile && <br />} the power to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Motivated: {
        mantra: (
          <span>
            It is through my own imagination {playlistSection[categoryBucket()].icon1} that{" "}
            {!isMobile && <br />} I find inspiration {playlistSection[categoryBucket()].icon2},
            motivating me to{!isMobile && <br />}&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Serene: {
        mantra: (
          <span>
            Through simple pauses {playlistSection[categoryBucket()].icon1} and small{" "}
            {!isMobile && <br />} moments, I find serenity {playlistSection[categoryBucket()].icon2}{" "}
            and slowness, {!isMobile && <br />} helping me to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Loved: {
        mantra: (
          <span>
            I connect intentionally and {playlistSection[categoryBucket()].icon1} spend my time{" "}
            {!isMobile && <br />} with people who light me up{" "}
            {playlistSection[categoryBucket()].icon2}, which gives {!isMobile && <br />} me the
            support to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Attentive: {
        mantra: (
          <span>
            In being present and gentle with myself {playlistSection[categoryBucket()].icon1}, I{" "}
            {!isMobile && <br />} am attentive and in the moment{" "}
            {playlistSection[categoryBucket()].icon2}, giving me {!isMobile && <br />} space
            to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Strong: {
        mantra: (
          <span>
            I am in awe of my strength {playlistSection[categoryBucket()].icon1}, which grows{" "}
            {!isMobile && <br />} day by day {playlistSection[categoryBucket()].icon2}, fueling my
            ability to{!isMobile && <br />}&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Inspired: {
        mantra: (
          <span>
            I find inspiration in simple {playlistSection[categoryBucket()].icon1} places,{" "}
            {!isMobile && <br />} giving me the stimulation{" "}
            {playlistSection[categoryBucket()].icon2} {!isMobile && <br />} I need to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      },
      Confident: {
        mantra: (
          <span>
            I admire myself more every day {playlistSection[categoryBucket()].icon1} and exude{" "}
            {!isMobile && <br />}
            self-assurance {playlistSection[categoryBucket()].icon2} which provides me with the{" "}
            {!isMobile && <br />}
            tools I need to&nbsp;
            <span
              style={{
                fontFamily: "inferi-normal-italic",
                textDecoration: "underline"
              }}>
              {forthAnswer.toLowerCase()}
            </span>
            .
          </span>
        )
      }
    };
  }, [categoryBucket, forthAnswer, isMobile]);

  const printRef = useRef<any>(null);

  const handleAbout = useCallback(() => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleRestart = useCallback(() => {
    localStorage.clear();
    navigate("/");
  }, [navigate]);

  const handleDownloadImage = useCallback(async () => {
    const element = printRef.current;
    if (element) {
      const html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 2000,
        logging: true,
        scale: 2,
        useCORS: true
      };
      const canvas = await html2canvas(element, html2canvasOptions);

      const image = new Image();
      image.src = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.download = "mantra";
      a.href = image.src;
      if (!isMobile) {
        a.target = "_blank";
      }
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [isMobile]);

  const answerObject = blurb[(thirdAnswer + forthAnswer).replace(/\s/g, "").toLowerCase()];

  const getCanvas = async () => {
    const element = printRef.current;
    let canvas: HTMLCanvasElement | null = null;
    if (element) {
      const html2canvasOptions = {
        allowTaint: true,
        removeContainer: true,
        backgroundColor: null,
        imageTimeout: 2000,
        logging: true,
        scale: 2,
        useCORS: true
      };
      canvas = await html2canvas(element, html2canvasOptions);
    }
    return canvas;
  };

  useEffect(() => {
    getCanvas().then((res) => {
      if (res) {
        setImageUrl(res.toDataURL("image/png"));
      }
    });
  }, []);

  return (
    <div>
      {!imageUrl && <div className={styles.loading}>Generating your mantra...</div>}
      <Header handleAbout={handleAbout} />
      <div className={styles.pageWrapper}>
        <div
          className={cc([
            {
              "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer),
              "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer),
              "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer),
              "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer),
              "tertiary-4 ": relationShipColorAnswers.includes(thirdAnswer)
            }
          ])}>
          <div className={styles.resultsHeading}>
            <span>First, see it:</span>
          </div>
          <div className={styles.motto}>
            Get started by saying this mantra every morning when you get out bed. This is the first
            step {!isMobile && <br />} to visualizing (and realizing) your intentions.
          </div>
          <div className={styles.fakePrint}>
            <div
              ref={printRef}
              className={cc([
                styles.fakeMantra,
                {
                  "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer),
                  "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer),
                  "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer),
                  "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer),
                  "tertiary-4 ": relationShipColorAnswers.includes(thirdAnswer),
                  [styles.mantraSvg]: relationShipColorAnswers.includes(thirdAnswer)
                }
              ])}>
              <div>{templates[templateAnswer].mantra}</div>
              <div className={styles.downloadLogo}>
                <Logo width='132' height='80' />
              </div>
            </div>
          </div>
          {isMobile ? (
            <div>
              {imageUrl && (
                <img
                  src={imageUrl || ""}
                  width='100%'
                  height={"auto"}
                  alt='mantra'
                  style={{ userSelect: "none" }}
                />
              )}
            </div>
          ) : (
            <div
              className={cc([
                styles.mantra,
                {
                  "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer),
                  "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer),
                  "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer),
                  "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer),
                  "tertiary-4 ": relationShipColorAnswers.includes(thirdAnswer),
                  [styles.mantraSvg]: relationShipColorAnswers.includes(thirdAnswer)
                }
              ])}>
              {templates[templateAnswer].mantra}
            </div>
          )}
          <div className={styles.downloadDesc}>
            <span>
              Download or screenshot your mantra results to have your healthiest year yet. Use it as
              your phone {!isMobile && <br />} background, print it out and put it on your fridge,
              or share it on social with friends {!isMobile && <br />} to help realize your results.
            </span>
          </div>
          <div className={styles.downloadButton}>
            <Button
              onClick={handleDownloadImage}
              bg={relationShipColorAnswers.includes(thirdAnswer) ? "bg-primary-2" : "bg-primary-3"}
              color='tertiary-4'>
              <>Download and share your {isMobile ? "" : "personalized"} self health mantra</>
            </Button>
          </div>
          <div className={styles.restart}>
            <Button
              onClick={handleRestart}
              bg='bg-transparent'
              bordered
              color={relationShipColorAnswers.includes(thirdAnswer) ? "tertiary-4 " : "tertiary-3"}>
              Start over
            </Button>
          </div>
        </div>
        <div
          className={cc([
            styles.shapeWrapper,
            {
              "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer),
              "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer),
              "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer),
              "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer),
              "tertiary-4 ": relationShipColorAnswers.includes(thirdAnswer)
            }
          ])}>
          <MantraWave />
        </div>
        <div className={styles.headingNext}>
          <span>Next, believe it:</span>
        </div>
        <div className={styles.believeDesc}>
          <span>
            To keep your intentions on track, focusing helps you prioritize what matters most to
            you. Here&apos;s some science-backed tips to help you realize your resolutions.
          </span>
        </div>
        <div className={styles.focusArea}>
          <div className={styles.focusHeader}>
            <span>FOCUS AREA</span>
          </div>
          <div className={styles.focusTitle}>
            <span>{thirdAnswer}</span>
          </div>
          <div className={styles.focusSections}>
            <div className={styles.firstSection}>
              <span>{answerObject?.blurb}</span>
            </div>
            <div className={styles.firstSection}>
              <ul>
                {answerObject?.tips?.map((value, index) => {
                  return <li key={index}>{value}</li>;
                })}
              </ul>
            </div>
            <div className={styles.thirdSection}>
              <div>
                <span>{`"${answerObject.quote}"`}</span>
              </div>
              <div className={styles.avatarWrapper}>
                <div className={styles.avatar}>
                  <img
                    src={author[answerObject.author].avatar}
                    alt='avatar'
                    width='60px'
                    height='60px'
                    style={{ borderRadius: "30px" }}
                  />
                </div>
                <div className={styles.avatarDesc}>
                  <div className={styles.name}>
                    <span>{author[answerObject.author].name}</span>
                  </div>
                  <div className={styles.description}>
                    <span>{author[answerObject.author].grad}</span>
                  </div>
                  <div className={styles.description}>
                    <span>{author[answerObject.author].position}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.headingNext}>
            <span>Finally, feel it:</span>
          </div>
          <div className={styles.believeDesc}>
            <span>
              At Tia, we believe that health isn&apos;t just physical, it&apos;s mental and
              emotional. {!isMobile && <br />} Because when you see it and believe it, you can truly
              start to feel it.
            </span>
          </div>
        </div>
        <div className={styles.playList}>
          <div>
            <img src={woman} alt='woman' width='100%' height='auto' />
          </div>
          <div className={styles.playListRight}>
            <div className={styles.playListHeader}>
              <span>Get your Self Health playlist</span>
            </div>
            <div className={styles.playlistDesc}>
              <span>{playlistSection[categoryBucket()].description}</span>
            </div>
            <div className={styles.playListInner}>
              <div
                className={cc([
                  styles.playListBanner,
                  {
                    "bg-secondary-5": creativeColorAnswers.includes(thirdAnswer),
                    "bg-primary-3": relationShipColorAnswers.includes(thirdAnswer),
                    "bg-tertiary-2": betterHealthColorAnswers.includes(thirdAnswer),
                    "bg-tertiary-1": futurePlansColorAnswers.includes(thirdAnswer),
                    "tertiary-4 ": relationShipColorAnswers.includes(thirdAnswer)
                  }
                ])}>
                {playlistSection[categoryBucket()].keyword}
                <div className={styles.playListSmall}>
                  <span>selfhealth.asktia.com</span>{" "}
                  <Logo
                    color={relationShipColorAnswers.includes(thirdAnswer) ? "white" : undefined}
                  />
                </div>
              </div>
              <div className={styles.playListCode}>
                <div className={styles.playListCodeDesc}>
                  <span>
                    From your Spotify app, select Search and {!isMobile && <br />} the camera icon
                    to scan and reveal your {!isMobile && <br />}
                    playlist.
                  </span>
                </div>
                <div className={styles.playListCodeImg}>
                  {playlistSection[categoryBucket()].barcode}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.playListWave}>
          <PlayListWave />
        </div>
        <div className={styles.appCards}>
          <div className={styles.cardsTitle}>
            <span>
              We’re here to help you become your healthiest self in 2023. We recommend booking...
            </span>
          </div>
          <div className={styles.cardsWrapper}>
            {isMobile ? (
              <Swiper
                speed={1000}
                spaceBetween={20}
                slidesPerView={1}
                slidesOffsetAfter={1}
                modules={[Autoplay]}
                centeredSlides
                loop
                autoplay={{ delay: 5000, disableOnInteraction: true }}>
                {appointments.map((value, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className={styles.card}>
                        <div className={styles.cardHeader}>
                          <div className={styles.cardTitle}>
                            <span>{value.title}</span>
                            <div className={styles.place}>
                              {value.icon} <span>{value.place}</span>
                            </div>
                          </div>
                          <div>
                            <img src={value.image} alt='apt image' width='56px' height='56px' />
                          </div>
                        </div>
                        <div className={styles.cardContent}>
                          <span>{value.content}</span>
                        </div>
                        <div className={styles.cardButton}>
                          <a href={value.link} target='_blank' rel='noreferrer noopener'>
                            <Button onClick={() => null} color='tertiary-4' bg='bg-primary-3'>
                              Book this appointment
                            </Button>
                          </a>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              appointments.map((value, index) => {
                return (
                  <div key={index} className={styles.card}>
                    <div className={styles.cardHeader}>
                      <div className={styles.cardTitle}>
                        <span>{value.title}</span>
                        <div className={styles.place}>
                          {value.icon} <span>{value.place}</span>
                        </div>
                      </div>
                      <div>
                        <img src={value.image} alt='apt image' width='56px' height='56px' />
                      </div>
                    </div>
                    <div className={styles.cardContent}>
                      <span>{value.content}</span>
                    </div>
                    <div className={styles.cardButton}>
                      <a href={value.link} target='_blank' rel='noreferrer noopener'>
                        <Button onClick={() => null} color='tertiary-4' bg='bg-primary-3'>
                          Book this appointment
                        </Button>
                      </a>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className={styles.aboutWave}>
          <AboutWave />
        </div>
        <div className={styles.aboutWrapper} ref={aboutRef}>
          <div className={styles.aboutContent}>
            <div>
              <div className={styles.title}>
                <span>At Tia, it all starts with you</span>
              </div>
              <div className={styles.Desc}>
                <span>
                  At Tia, we believe that women’s health isn&apos;t purely physical,{" "}
                  {!isMobile && <br />} it’s mental and emotional. So we go beyond body parts{" "}
                  {!isMobile && <br />} and believe in gaining a more complete picture of your{" "}
                  {!isMobile && <br />} whole health. Finally, healthcare that treats the whole you.
                </span>
              </div>
              <div className={styles.Button}>
                <a href='https://asktia.com/' target='_blank' rel='noreferrer noopener'>
                  <Button onClick={() => null} bg='bg-primary-3' color='tertiary-4' bordered>
                    Discover a new standard of care
                  </Button>
                </a>
              </div>
            </div>
            <div>
              <img src={talk} width='60%' height='auto' alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
