import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/bundle";
import "swiper/css";
import { Meditation, ManHead, Coffee, Heart, Cloud, Up, Nap, Bulb } from "../../UI/Svgs";
import styles from "./styles.module.css";

const isMobile = /Android|iPhone/i.test(navigator.userAgent);

const FirstSlide = () => {
  return (
    <div className={`${styles.itemWrapper} bg-tertiary-2`}>
      <span>
        I am buzzing with energy{" "}
        <span className={styles.bulbWrapper}>
          <Up />
        </span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;, nourished by {!isMobile && <br />} balance and rest
        <span className={styles.bulbWrapper}>
          <Nap />
        </span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, which gives me the {!isMobile && <br />}
        power to&nbsp;
        <span className={styles.keyword}>build strength</span>.
      </span>
    </div>
  );
};

const SecondSlide = () => {
  return (
    <div className={`${styles.itemWrapper} bg-tertiary-1`}>
      <span>
        It is through my own imagination{" "}
        <span className={styles.bulbWrapper}>
          <Cloud />
        </span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; that {!isMobile && <br />} I find inspiration{" "}
        <span className={styles.bulbWrapper}>
          <Bulb />
        </span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;, motivating me to&nbsp;{!isMobile && <br />}
        <span className={styles.keyword}>save for up for a big purchase</span>.
      </span>
    </div>
  );
};

const ThirdSlide = () => {
  return (
    <div className={`${styles.itemWrapper} bg-secondary-5`}>
      <span>
        Through simple pauses{" "}
        <span className={styles.bulbWrapper}>
          <ManHead />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp; and small {!isMobile && <br />} moments, I find serenity{" "}
        <span className={styles.bulbWrapper}>
          <Meditation />
        </span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; and slowness, {!isMobile && <br />} helping me
        to&nbsp;
        <span className={styles.keyword}>support my whole health</span>.
      </span>
    </div>
  );
};

const ForthSlide = () => {
  return (
    <div className={`${styles.itemWrapper} bg-primary-3 tertiary-4`}>
      <span>
        I am in awe of my strength{" "}
        <span className={styles.bulbWrapper}>
          <Heart />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;, which {!isMobile && <br />} grows day by day, fueling{" "}
        <span className={styles.bulbWrapper}>
          <Coffee />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; my ability to&nbsp;{!isMobile && <br />}
        <span className={styles.keyword}>express myself in a new way</span>.
      </span>
    </div>
  );
};

const Carousel = () => {
  const isMobile = /Android|iPhone/i.test(navigator.userAgent);
  return (
    <div className={styles.carouselWrapper}>
      <Swiper
        speed={1000}
        spaceBetween={isMobile ? 10 : 20}
        slidesPerView={isMobile ? 1 : 3}
        slidesOffsetAfter={1}
        modules={[Autoplay]}
        centeredSlides
        loop
        autoplay={{ delay: 5000, disableOnInteraction: true, pauseOnMouseEnter: true }}>
        <SwiperSlide>
          <FirstSlide />
        </SwiperSlide>
        <SwiperSlide>
          <SecondSlide />
        </SwiperSlide>
        <SwiperSlide>
          <ThirdSlide />
        </SwiperSlide>
        <SwiperSlide>
          <ForthSlide />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Carousel;
