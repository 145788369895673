import { MouseEventHandler } from "react";
import cc from "classcat";
import styles from "./styles.module.css";

const Tag = ({
  value,
  selected,
  onClick,
  onDeselect
}: {
  value: string;
  selected: boolean;
  onClick: MouseEventHandler<HTMLSpanElement>;
  onDeselect: (value: string) => void;
}) => {
  return (
    <span
      onClick={(e) => onClick(e)}
      className={cc([
        styles.tag,
        {
          [styles.selectedTag]: selected
        }
      ])}>
      {value}
    </span>
  );
};

export default Tag;
