const VirtualClinic = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.99984 1.32129C4.74817 1.32129 2.9165 3.15296 2.9165 5.40462C2.9165 7.83712 5.49484 11.1913 6.5565 12.4688C6.78984 12.7488 7.21567 12.7488 7.449 12.4688C8.50484 11.1913 11.0832 7.83712 11.0832 5.40462C11.0832 3.15296 9.2515 1.32129 6.99984 1.32129ZM6.99983 2.488C7.6415 2.488 8.1665 3.013 8.1665 3.65467C8.1665 4.30217 7.6415 4.82133 6.99983 4.82133C6.35816 4.82133 5.83316 4.30217 5.83316 3.65467C5.83316 3.013 6.35816 2.488 6.99983 2.488ZM4.66651 7.06701C5.16818 7.82534 6.02568 8.32117 6.99985 8.32117C7.97401 8.32117 8.83151 7.82534 9.33318 7.06701C9.32151 6.29701 7.77568 5.87117 6.99985 5.87117C6.22401 5.87117 4.67818 6.29701 4.66651 7.06701Z'
        fill='#831A4A'
      />
    </svg>
  );
};

export default VirtualClinic;
