import { MouseEventHandler, ReactElement } from "react";
import styles from "./styles.module.css";
import cc from "classcat";

const Button = ({
  children,
  onClick,
  disabled,
  outlined,
  color = "secondary-3",
  bg = "bg-secondary-2",
  bordered
}: {
  children: string | ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  outlined?: boolean;
  color?: string;
  bg?: string;
  bordered?: boolean;
}) => {
  return (
    <button
      className={cc([
        styles.button,
        color,
        bg,
        { [styles.outlined]: outlined, [styles.bordered]: bordered }
      ])}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
