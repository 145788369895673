import { ReactElement } from "react";
import {
  Abundance,
  Closeness,
  Betterment,
  Readiness,
  Up,
  Nap,
  Cloud,
  Bulb,
  ManHead,
  Meditation,
  Heart,
  Coffee
} from "../UI/Svgs";
import christina from "../assets/images/christina.png";
import devon from "../assets/images/devon.png";
import julia from "../assets/images/julia.png";

export const answerShortList = {
  "Mindful eating": [
    "support my whole health",
    "feel nourished and energized",
    "build healthier eating habits longterm"
  ],
  "Dedicating time with loved ones": [
    "recharge and find support",
    "have more work/life balance",
    "build new memories"
  ],
  "Saving money": [
    "feel empowered about future plans",
    "save for something meaningful",
    "reduce financial stress"
  ],
  "Building a budget": [
    "be in control of my expenses",
    "save for something meaningful",
    "feel at ease with my finances"
  ],
  "Drinking less alcohol": [
    "physically feel better (less bloated, less fatigue, ect)",
    "support my whole health",
    "support my fertility journey"
  ],
  "Starting a family": ["take a first step towards parenthood", "begin my fertility journey"],
  "Waking up earlier": ["feel more productive", 'have "me" time', "be able to slow down"],
  "Taking breaks throughout the day": [
    'have "me" time',
    "stretch and connect to my body more",
    "start (or kickstart) a meditation practice"
  ],
  "Reading more": [
    "inspire future plans",
    "learn more about a specific topic",
    "challenge my perspective"
  ],
  "Being on time": [
    "not be the late friend of the group",
    "be better about time management",
    "be less stressed"
  ],
  "Building strength": [
    "increase flexibility and mobility",
    "work towards my fitness goals",
    "support my whole health"
  ]
};

export const answerLongList: { [key: string]: string[] } = {
  "Mindful eating": [
    "support my whole health",
    "feel nourished and energized",
    "build healthier eating habits longterm"
  ],
  "Dedicating time with loved ones": [
    "recharge and find support",
    "have more work/life balance",
    "build new memories"
  ],
  "Saving money": [
    "feel empowered about future plans",
    "save for something meaningful",
    "reduce financial stress"
  ],
  "Building a budget": [
    "be in control of my expenses",
    "save for something meaningful",
    "feel at ease with my finances"
  ],
  "Drinking less alcohol": [
    "physically feel better (less bloated, less fatigue, ect)",
    "support my whole health",
    "support my fertility journey"
  ],
  "Starting a family": ["take a first step towards parenthood", "begin my fertility journey"],
  "Waking up earlier": ["feel more productive", 'have "me" time', "be able to slow down"],
  "Taking breaks throughout the day": [
    'have "me" time',
    "stretch and connect to my body more",
    "start (or kickstart) a meditation practice"
  ],
  "Reading more": [
    "inspire future plans",
    "learn more about a specific topic",
    "challenge my perspective"
  ],
  "Being on time": [
    "not be the late friend of the group",
    "be better about time management",
    "be less stressed"
  ],
  "Building strength": [
    "increase flexibility and mobility",
    "work towards my fitness goals",
    "support my whole health"
  ],
  "Being eco-conscious": ["protect the environment"],
  "Meeting new people": [
    "network and support my goals",
    "build more meaningful connections",
    "broaden my perspective"
  ],
  "Reducing screen time": ["be more present"],
  "Having better sex": ["feel more pleasure with my partner", "feel more pleasure with myself"],
  "Becoming more organized": ["feel more productive daily", "find clarity around my goals"],
  "Adopting a pet": [
    "exercise my heart muscle",
    "support my mood and emotional health",
    "take a first step towards parenthood"
  ],
  "Expressing yourself": ["feel and be heard", "foster creativity"],
  Journaling: [
    "get more clarity about my thoughts & feelings",
    "document my life for future reflections",
    "reduce stress by noticing my emotions"
  ],
  "Finding a creative outlet": [
    "express myself in a new way",
    "feel inspired daily",
    "spend time on things that bring me joy"
  ],
  "Drinking more water": ["support skin health from the inside out", "support my whole health"],
  Volunteering: [
    "push my brain to learn new skills",
    "feel of service",
    "connect with like-minded people"
  ],
  Traveling: ["connect with my roots", "have new experiences", "broaden my perspective"],
  "Changing jobs": ["move towards my goals", "ease financial stress"]
};

export const fifthAnswers = ["Energized", "Motivated", "Serene", "Loved"];

export const fifthAnswersSecond = ["Attentive", "Strong", "Inspired", "Confident"];

export const creativeColorAnswers = [
  "Waking up earlier",
  "Reading more",
  "Being on time",
  "Being eco-conscious",
  "Becoming more organized",
  "Expressing yourself",
  "Journaling",
  "Finding a creative outlet",
  "Volunteering"
];

export const relationShipColorAnswers = [
  "Dedicating time with loved ones",
  "Starting a family",
  "Meeting new people",
  "Having better sex"
];

export const betterHealthColorAnswers = [
  "Mindful eating",
  "Drinking less alcohol",
  "Taking breaks throughout the day",
  "Building strength",
  "Reducing screen time",
  "Adopting a pet",
  "Drinking more water"
];

export const futurePlansColorAnswers = [
  "Saving money",
  "Building a budget",
  "Traveling",
  "Changing jobs"
];

export const blurb: {
  [key: string]: { blurb: string; tips: string[]; quote: string; author: string };
} = {
  mindfuleatingsupportmywholehealth: {
    blurb:
      'Our body only has a chance to rest, heal injured cells, and digest when we\'re relaxed. Many of us eat in front of the TV or while scrolling on our phone. We recommend taking pause to eat and truly enjoy the sensations. Eating in a relaxed state allows the production of adequate digestive enzymes and lets our parasympathetic nervous system — also known as the "rest and digest" system — do its thing.',
    tips: [
      "Talk to a dietician or nutritionist! They are taught to think of food as medicine and can help you reframe your relationship with food.",
      "Make sure to discuss changes in your diet with your physician. Certain foods such as milk or beans can have interactions with different types of medications.",
      "Track changes in your notes app — over time your diet may cause you to experience new side effects or symptoms."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between the two.",
    author: "christina"
  },
  mindfuleatingfeelnourishedandenergized: {
    blurb:
      "Hunger is both a physical and emotional sensation. We've all heard it before — eat less sugar, don't go crazy eating saturated fats, and don't drink too much. At Tia, we want you to be gentle with yourself, and instead of obsessing over removing things from your diet, we recommend adding in foods. More specifically, foods full of protein, fiber, and antioxidants!",
    tips: [
      "Start with small, meaningful, healthy changes. Choose a side salad instead of fries or add a scoop of veggies at dinner — these things add up.",
      "Add in a small 10-15 minute walk after eating! This will train your body to expect more movement in the day and support your energy levels.",
      "Need a pick me up? Be weary of any food or drink that promises instant energy. They're often packed with unhealthy chemicals."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  mindfuleatingbuildhealthiereatinghabitslongterm: {
    blurb:
      "Choosing what to eat is an individualized process — it depends on your allergies, sensitivities, what foods make you feel energized, and what brings you comfort. Eating regularly helps to keep your circadian rhythms and hormones fluctuating normally. Regular eating times help the gut and liver absorb nutrients more effectively from food, and the breaks in between meals help to burn stored fat and repair damaged cells.",
    tips: [
      "Building new habits is hard, keeping them is harder. Give yourself a break by keeping fresh veggies or nuts on hand when you're feeling hungry.",
      "Indulge in your cravings when you're having them. By acknowledging our needs, there's less of a risk that you'll binge.",
      "Just like brushing your teeth, if you missed one time that doesn't mean you stop altogether. Just get back on track at the next meal!"
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  dedicatingtimewithlovedonesrechargeandfindsupport: {
    blurb:
      "Connection is healing. It helps regulate our heart and breathing rates, influences the emotional activation in our brains, shifts our immune response, changes our exposure to stressors, and modulates our stress response. So by nurturing relationships that light you up, you're nurturing yourself in a physiological way.",
    tips: [
      'There is no such thing as "unquality" time. Making time to "just be" with loved ones, especially children, is a great way to improve social bonds.',
      "Research shows that giving compliments can make you feel good and deepen your sense of connection. It's also a free way to make someone else feel good.",
      "Take interest in what your loved ones are doing! Bonus points: doing something together will leave you feeling more relaxed in the long term."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  "dedicatingtimewithlovedoneshavemorework/lifebalance": {
    blurb:
      "When we are isolated, we are much more likely to be in a state of fight or flight. Isolation can lead to health issues like high blood pressure, ulcers, and poor sleep, and can affect our metabolism, immune system, and ability to learn. Unfortunately isolation creates a feedback loop: with isolation you go into fight or flight, you’re less open to being social, and you’re less open to receiving information and cooperating.",
    tips: [
      'There is no such thing as "unquality" time. Making time to "just be" with loved ones, especially children, is a great way to improve social bonds.',
      "Put guardrails around your workday, so that you and your coworkers know when you plan to unplug. Once it's past the set time, put the computer away.",
      'Find out what is "good enough." You could spend at least another 2 hours on every project or you could spend more time with loved ones!'
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  dedicatingtimewithlovedonesbuildnewmemories: {
    blurb:
      "Direct person-to-person contact triggers the part of our nervous system that releases neurotransmitters tasked with regulating our response to stress and anxiety. Cuddling or touching someone you love can actually help to relieve physical pain. Skin blood vessels are simultaneously innervated by sensory, sympathetic, and parasympathetic fibers — this means that our skin is innervated for pleasure.",
    tips: [
      "The best memories are made with someone. Find interesting ways to celebrate birthdays, anniversaries and the every day too!",
      "Try something new! Remember that thing you and your loved one said you would try eventually? Well, today can be the day!",
      "Be careful of trying to do everything at once or spending an uncomfortable amount of money on one thing. Things may not always go according to plan and that's alright. You can always try again!"
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  savingmoneyfeelempoweredaboutfutureplans: {
    blurb:
      "Sometimes when we are sad or anxious, we might feel like we need a quick pick me up to feel better (retail therapy, anyone?). While the purchase may give us a boost of dopamine in the short term, it might contribute to more financial stress in the long term. Whether you're saving for a short-term or long-term goal (or both), you are taking the initiative to care for your future self. Brava!",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  savingmoneysaveforsomethingmeaningful: {
    blurb:
      "Sometimes when we are sad or anxious, we might feel like we need a quick pick me up to feel better (retail therapy, anyone?). While the purchase may give us a boost of dopamine in the short term, it might contribute to more financial stress in the long term. Whether you're saving for a short-term or long-term goal (or both), you are taking the initiative to care for your future self. Brava!",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  savingmoneyreducefinancialstress: {
    blurb:
      "Sometimes when we are sad or anxious, we might feel like we need a quick pick me up to feel better (retail therapy, anyone?). While the purchase may give us a boost of dopamine in the short term, it might contribute to more financial stress in the long term. Whether you're saving for a short-term or long-term goal (or both), you are taking the initiative to care for your future self. Brava!",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  buildingabudgetbeincontrolofmyexpenses: {
    blurb:
      "Did you know a budget can help you feel more in control leading to feelings of relaxation? Remember that figuring out the right budget for you may take some time, so be patient with yourself (and know that unexpected expenses are bound to come up).",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  buildingabudgetsaveforsomethingmeaningful: {
    blurb:
      "Did you know a budget can help you feel more in control leading to feelings of relaxation? Remember that figuring out the right budget for you may take some time, so be patient with yourself (and know that unexpected expenses are bound to come up).",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  buildingabudgetfeelateasewithmyfinances: {
    blurb:
      "Did you know a budget can help you feel more in control leading to feelings of relaxation? Remember that figuring out the right budget for you may take some time, so be patient with yourself (and know that unexpected expenses are bound to come up).",
    tips: [
      "First, become clear on your priorities. We recommend writing down a list of your goals or values – such as traveling, spending time with loved ones, or adopting a pet.",
      "Next, track how you spend your money. Take note of when and where your dollars are going across categories, e.g. food, utilities, entertainment, subscriptions.",
      "Compare your lists. Does your money go towards your priorities? Start making strides on spending money on what is most important to you."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  "drinkinglessalcoholphysicallyfeelbetter(lessbloated,lessfatigue,ect)": {
    blurb:
      "We turn to alcohol to lift our spirits, quell our anxieties, and make us feel good. But in truth, alcohol wreaks havoc on the body, increasing inflammation, damaging the GI tract, and other organs. Because it's packed with sugar, it's also can disrupt your sleep cycle, cause dehydration, and impact your mood. The TL;DR: Drinking less is one of the easiest ways to lift your spirit and actually feel good.",
    tips: [
      "Take note of when, why, and how much you drink. The first step to changing is tracking!",
      "Start small and be realistic. Consider limiting the drinking you do at home and when you're out or opt for something with a lower alcohol by volume.",
      "Increase the amount of fiber in your diet to feel less bloated. Cut down on alcohol intake for a powerful double whammy!"
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  drinkinglessalcoholsupportmywholehealth: {
    blurb:
      "At Tia, we are pro-pleasure and would never shame anyone for drinking a margarita or a glass of wine. That said, we recommend being mindful about binge drinking (drinking 4 or more drinks within 2 hours). Women are more likely to be binge drinkers than males thanks to fluctuating hormones that trigger a buzz from alcohol. The hormone estrogen is thought to respond to alcohol by releasing dopamine in the brain, a chemical that gives us feelings of happiness.",
    tips: [
      "Take note of when, why, and how much you drink. The first step to changing is tracking!",
      "Be aware of what interactions alcohol may have with any medications you're taking. Talk to a physician or pharmacist, and read any medication inserts.",
      "Talk to a physician if you feel like your relationship with alcohol is unhealthy or if you need help stopping."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  drinkinglessalcoholsupportmyfertilityjourney: {
    blurb:
      "Will having a few drinks throw off your cycle? There is conflicting research and therefore, no clear answer. Some studies note relationships between alcohol consumption and cycle irregularities, but generally only when alcohol is consumed chronically at high doses. When looking at moderate drinking, there may be no measurable change in menstrual cycle function.",
    tips: [
      "Some evidence suggests alcohol consumed in your luteal phase (the second half of your menstrual cycle) may have more of an effect on your mood.",
      "If trying to conceive with a partner, consider having them reduce intake as well!",
      "Talk to a physician if you feel like your relationship with alcohol is unhealthy or if you need help stopping."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  startingafamilytakeafirststeptowardsparenthood: {
    blurb:
      "There are many different ways to start or build a family. If you or your partner are considering pregnancy, it can be helpful to prepare physically, emotionally, and financially. Of course, not everyone has a chance to plan ahead, and that's fine too!",
    tips: [
      "Schedule a Tia Whole Health exam to make sure your health is in the best place it can be. We can also do additional screenings that are relevant for people considering pregnancy in the next year.",
      "If you are in a relationship, invest in some time to connect with your partner. Deepening your bond with each other will help you both to feel supported and connected as you experience new challenges and adventures.",
      "Review your insurance plan. If you have the option of switching to a plan that provides better pregnancy or fertility benefits, consider switching plans during your benefits open enrollment."
    ],
    quote:
      "Whether you are thinking of starting a family, are trying to conceive, or have chosen not to, everyone seems to have an opinion. Stay true to you and lean on those who support you building the life you want!",
    author: "devon"
  },
  startingafamilybeginmyfertilityjourney: {
    blurb:
      "Whether you are considering trying to conceive in the short or long term, have been trying for a while, or are just curious, it's never a bad time to talk to your provider about your fertility. Your team at Tia can help you understand your fertility through listening to your history and experience, and conducting simple blood tests or ultrasound when appropriate.",
    tips: [
      "Schedule a fertility appointment to make sure your health is in the best place it can be for trying to conceive. We can also identify health concerns that could impact fertility.",
      "Having support is important. If you have someone you are sharing the fertility journey with, it may be helpful to bring them along to your appointment, if that feels good to you.",
      "Review your insurance plan. If you have the option of switching to a plan that provides better pregnancy or fertility benefits, consider switching plans during your benefits open enrollment."
    ],
    quote:
      "Whether you are thinking of starting a family, are trying to conceive, or have chosen not to, everyone seems to have an opinion. Stay true to you and lean on those who support you building the life you want!",
    author: "devon"
  },
  wakingupearlierfeelmoreproductive: {
    blurb:
      "Sleep is a state of internal wakefulness. It is a complex, metabolically active, and deliberately ordered series of unique stages. During these hours, our brains clean and organize themselves (by removing synapses and processing memories) for the purpose of optimizing brain function. This is the reason you might feel slow or have brain fog if you don't have a restful night of sleep.",
    tips: [
      "Aim to avoid eating within two to three hours of bedtime to improve sleep quality and prevent heartburn, night sweats, and blood sugar dysregulation.",
      "Use an alarm clock that wakes you up using light! Some people find this helps them to feel fully awake more quickly.",
      "We don't recommend having caffeinated or sugary drinks late at night. They can make it more difficult to fall asleep and stay asleep!"
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  'wakingupearlierhave"me"time': {
    blurb:
      "Sleep is a state of internal wakefulness. It is a complex, metabolically active, and deliberately ordered series of unique stages. During these hours, our brains clean and organize themselves (by removing synapses and processing memories) for the purpose of optimizing brain function. This is the reason you might feel slow or have brain fog if you don't have a restful night of sleep.",
    tips: [
      "Consider taking stock of the day before checking your phone in the morning. This sets up your day to be more focused and for you to be more aware!",
      "Try placing your phone or alarm clock in a place that can't be reached from bed. This can prep your body to be more thoughtful in the morning.",
      "Find an early morning stretch or exercise routine! Getting the blood pumping is a great way to improve your overall health and start your day."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  wakingupearlierbeabletoslowdown: {
    blurb:
      "Not getting enough sleep and frequent awakenings during the night may contribute significantly to worsened mood and negative emotions. Someone who is sleep deprived is more likely to be sad, irritable, frustrated, stressed out, and fatigued. This can even happen when sleep is restricted by only a few hours.",
    tips: [
      "Cortisol levels are highest when you wake, so try to wait an hour before your morning cup of joe to let your natural cortisol levels drop first.",
      "Try falling asleep earlier. Waking up on time and feeling well-rested will guarantee that you have the time to start your day.",
      "Take notice of what makes you feel rushed in the morning. Is there a way to smooth out that snag, like laying out your clothes the night before?"
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  'takingbreaksthroughoutthedayhave"me"time': {
    blurb:
      'Breaks can prevent "decision fatigue." We need to make frequent decisions throughout the day, which is exhausting and can wear down willpower and reasoning ability. When we work, our prefrontal cortex makes every effort to help us execute our goals. For challenging tasks that require sustained attention, research shows briefly taking our minds off the goal can help us to renew and strengthen motivation.',
    tips: [
      "Try journaling! Many people find journaling a therapeutic way to perform self-reflection.",
      "Many people find that the best way to center themselves is to help others. A sincere compliment can be an easy, free way to help yourself and others!",
      "Consider going for a break outside the office to get you outside. Bonus points if you leave your phone at your desk!"
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  takingbreaksthroughoutthedaystretchandconnecttomybodymore: {
    blurb:
      "Our mind naturally seeks a break after around 25 minutes. Without taking breaks, you’re draining your energy without ever refueling it, deteriorating your ability to focus and causing fatigue and exhaustion. Taking frequent breaks helps you keep your energy levels consistent and allows you to restore your ability to focus.",
    tips: [
      "Set a timer or find an app that will remind you to get up and move throughout the day!",
      "Find stretches you enjoy that are doable in work clothes. This could also mean finding work clothes that are more comfortable.",
      "If you work from home, take a walk after lunch. It can be a great way to get some movement and sunshine."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  "takingbreaksthroughoutthedaystart(orkickstart)ameditationpractice": {
    blurb:
      'If your job is physically demanding or mentally challenging, meditation is an excellent way to relieve the tension that builds up in the body and mind during the workday. Meditating after work also creates a helpful, clear boundary between your active work life and your personal life. Regular quality "you" time can help you leave work behind and be more available for the other important things in your life, like loved ones and hobbies.',
    tips: [
      "Find a place near where you work that feels centered or calming. It doesn't have to make sense, it just has to feel that way for you!",
      "Figure out what meditation means for your body. Is it standing, walking, or sitting still? What helps you feel most at peace?",
      "An easy way to start meditating is to focus on your breath. Try to lengthen your inhales and exhales."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  readingmoreinspirefutureplans: {
    blurb:
      "Not only does regular reading make you smarter, it can also increase your brainpower. Just like going for a jog exercises your cardiovascular system, regularly reading improves memory function by giving your brain a good workout. As we age, it is normal to experience a decline in memory and brain function. Regular reading helps to slow this process.",
    tips: [
      "Visit your local library for great physical and digital resources.",
      "Write down a list of topics that interest you – then look for authors and experts who write on those topics.",
      "Find a time that works for you to read every day. Reading can be a great alternative to your phone before bed."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  readingmorelearnmoreaboutaspecifictopic: {
    blurb:
      "Lifelong learning keeps your memory sharp and supports overall well-being — research has found that continued learning offers mental health benefits, like reduced anxiety.",
    tips: [
      "Visit your local library for great physical and digital resources.",
      "Write down a list of topics that interest you – then look for authors and experts who write on those topics.",
      "Find a time that works for you to read every day. Reading can be a great alternative to your phone before bed."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  readingmorechallengemyperspective: {
    blurb:
      "According to research, literary fiction has the power to help readers understand what others are thinking by reading other people's emotions. It gives us the ability to understand another person's mental state and complex social relationships.",
    tips: [
      "Visit your local library for great physical and digital resources.",
      "While reading, don't be afraid to write down your thoughts or ideas in a journal. Take note of what passages or phrases interest you!",
      "Find a time that works for you to read every day. Reading can be a great alternative to your phone before bed."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  beingontimenotbethelatefriendofthegroup: {
    blurb:
      "Being on time gives people a sense that they are worthy of your consideration, so improving your punctuality supports healthier relationships. However, don't beat yourself up about being late in the past — a Harvard study found that folks who are late tend to have lower blood pressure and are often calmer.",
    tips: [
      "While you are starting out, try to be five minutes early!",
      "Take notice of what is making you late. See if there is something you can change to remove those roadblocks.",
      "Being late once isn't a reason to give up. Try to be on time with the next opportunity."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  beingontimebebetterabouttimemanagement: {
    blurb:
      "Reasons for lateness are generally complex. Lateness may be caused by a reluctance to change gears — to end one activity and start another. Stopping something we are absorbed in to do something else can be annoying. Research shows that forever-latecomers go through life more relaxed and easygoing with lower stress levels, which is associated with positive health benefits.",
    tips: [
      "Start tracking your time segments during the day. How much time are you devoting to each task? Do you feel like that is the right amount of time?",
      "Learn when to say \"good enough!\" It's easy to toss time away that won't improve the end product. Learn to identify when to stop.",
      "Try to eliminate multitasking. Our brains are designed to process one task at a time. This is doubly true when trying to listen to someone!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  beingontimebelessstressed: {
    blurb:
      "Lateness causes stress, anxiety, guilt, and shame. We’ve all experienced that awful feeling — sitting in traffic, tapping your feet, and willing the cars in front of you to move as though you had some psychokinetic power. You could be experiencing hours of unnecessary stress every week.",
    tips: [
      "Ask yourself: are there particular tasks that stress you out more than others? Try to devote a couple of extra minutes at either end to prepare and process!",
      "Calendar apps are your friends. Use them to keep your day moving!",
      "Try to eliminate multitasking. Our brains are designed to process one task at a time. This is doubly true when trying to listen to someone!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  buildingstrengthincreaseflexibilityandmobility: {
    blurb:
      "Increased flexibility can decrease the risk of injury. In a normal healthy joint, movement increases the flow of blood to the joint capsule, providing the nutrients, oxygen and lubrication that enable the bones of the joint to move smoothly and without pain. When joint mobility is impaired, blood flow declines and the joints become stiff and painful. Stiffness and pain, in turn, prompt people to avoid further movement.",
    tips: [
      "Find a stretch you like that is easy to do! A full yoga routine might not work, but what about starting your morning with a downward dog?",
      "Mobility only improves with consistency. Start at a comfortable level to build a routine before adding in a challenge.",
      "Get more specific. Set a goal, like touching your toes. Keep tabs on your progress!"
    ],
    quote:
      "Increasing muscle mass has many health benefits, such as increasing bone mineral density, raising resting metabolic rate, and stabilizing blood sugar. A little goes a long way!",
    author: "devon"
  },
  buildingstrengthworktowardsmyfitnessgoals: {
    blurb:
      "Regular exercise benefits every organ and body system. We are evolutionarily predisposed to require it for optimal health. Over millions of years, environmental changes required early humans to undergo physical transformations to promote greater aerobic capacity and endurance so they could hunt and survive.",
    tips: [
      "Running not your thing? That's okay. Research shows that a brisk walk can boost heart health and decrease mortality.",
      "Group exercise has benefits beyond physical activity; it reduces stress and enhances the release of endorphins dramatically more than working out alone.",
      "Get more specific. Set a goal, like touching your toes. Keep tabs on your progress!"
    ],
    quote:
      "Increasing muscle mass has many health benefits, such as increasing bone mineral density, raising resting metabolic rate, and stabilizing blood sugar. A little goes a long way!",
    author: "devon"
  },
  buildingstrengthsupportmywholehealth: {
    blurb:
      "Brain-derived neurotrophic factor (BDNF), an excercise-induced protein, plays a role in strengthening connections between brain cells and promoting their growth and survival. When you get your heart pumping, your circulatory system brings more blood to your muscles and delivers white blood cells throughout the body, which detect pathogens and fight infections. Consistent exercise can also decrease inflammation.",
    tips: [
      "Physical activity might help you heal faster. Exercise has been shown to reduce levels of inflammation and promote rapid healing.",
      "Running not your thing? That's okay. Research shows that a brisk walk can boost heart health and decrease mortality. ",
      "Group exercise has benefits beyond physical activity; it reduces stress and enhances the release of endorphins dramatically more than working out alone."
    ],
    quote:
      "Increasing muscle mass has many health benefits, such as increasing bone mineral density, raising resting metabolic rate, and stabilizing blood sugar. A little goes a long way!",
    author: "devon"
  },
  "beingeco-consciousprotecttheenvironment": {
    blurb:
      "Eco-anxiety is disproportionately experienced by women, who often bear the brunt of the inequitable domestic mental load. Whether it's reducing energy consumption at home, using reusable bags, or supporting environmentally-conscious companies, there are many ways to contribute to a sustainable future. By adopting eco-friendly practices, you can not only help protect the planet, you can also reduce your eco-anxiety and contribute to a healthier, more equitable world.",
    tips: [
      "Get involved with a local environmental organization, and take the time to get to know the people involved.",
      "Put your dollars where it counts. Find trustworthy, go-to green brands for all your needs!",
      "Figure out what products work for you! For most people, all-natural deodorant is not a realistic alternative. What is it for you?"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  meetingnewpeoplenetworkandsupportmygoals: {
    blurb:
      "We are social animals which means we have to be social to survive. People who feel more connected have higher self-esteem and are more empathetic to others. We’ve evolved to be social creatures that perform at best when we’re intimately connected to others. Without connection we feel unease, and physical and mental symptoms of anxiety and depression. Good social connections can decrease depressive symptoms by ~64%.",
    tips: [
      "Find a space where the people you would like to meet congregate. Bonus points if you can get involved in a teamwork environment like a sports league!",
      "Recognize that networking is a skill! As you start, you might be bad at it, but that is not a reason to stop trying!",
      "Be honest with yourself and don't force it. Some friendships may blossom overnight, and others might take awhile."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between then two.",
    author: "christina"
  },
  meetingnewpeoplebuildmoremeaningfulconnections: {
    blurb:
      "Our ability to cooperate is one of the main reasons we have managed to survive in every ecosystem on earth, from scorching sun-baked deserts to the frozen wastes of Antarctica. Without the capacity to feel what someone else feels—to be interested in their fears, motives, and longings—we would have never evolved. Connection is intrinsic to our survival as a species, it regulates our immune and endocrine systems, and helps us recover from anxiety and depression faster.",
    tips: [
      "The first step to building more meaningful connections is listening. Focus on listening to listen instead of listening to respond.",
      "Increase the frequency of giving out sincere compliments. This is a sure-fire way to deepen connections fast.",
      "Be honest with yourself and don't force it. Some friendships may blossom overnight, and others might take awhile."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  meetingnewpeoplebroadenmyperspective: {
    blurb:
      "We are social animals which means we have to be social to survive. People who feel more connected have higher self-esteem and are more empathetic to others. We’ve evolved to be social creatures that perform at best when we’re intimately connected to others. Without connection we feel unease, and physical and mental symptoms of anxiety and depression. Good social connections can decrease depressive symptoms by ~64%.",
    tips: [
      "Broadening your perspective is hard — it requires putting yourself in an uncomfortable space. Get ready!",
      "Write down why you want to broaden your perspective! Keep that in mind as you go out.",
      "Start at home. A great way to broaden your perspective is by reading or watching movies. What stories do they tell? What do you feel that they leave out?"
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  reducingscreentimebemorepresent: {
    blurb:
      "The data is clear: more time alone with your glowing screen makes you more unhappy. Several longitudinal studies of teenagers show a direct correlation with unhappiness rising proportionally as screen time increases. Heavy users of social media are more likely to be depressed and lonely, and a recent study showed that decreasing your screen time by even 30 minutes a day can help reduce these feelings.",
    tips: [
      "Switch your phone into black and white or grayscale. This lack of bright colors makes the phone a less interesting object to your brain.",
      "Set a time restriction on the apps you use most. You will be surprised how quickly this cuts down on your device usage!",
      "Charge somewhere other than directly next to your bed. Making it even a little less difficult to access can have a great effect on usage."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  havingbettersexfeelmorepleasurewithmypartner: {
    blurb:
      "Sexual desire and pleasure are incredibly individual and context-dependent. Some of us have spontaneous desire—we look at our lover and decide we want sex. Some of us have responsive desire—when someone makes sexual approaches toward us, we decide to accept or reject their overtures. Some of us experience neither and some of us experience both! No matter what, we recommend that you put pleasure at the center of your definition of sexual wellbeing, not orgasms.",
    tips: [
      "Activities like yoga, gardening, and exercise that connect us to our senses and get us into our bodies are more likely to open us up to being sexual.",
      "Consider using a botanical massage oil called Zestra. One study found it increased arousal and pleasure.",
      "Omega-3 fatty acids — found in fish and walnuts — are building blocks for testosterone, estrogen and progesterone and can help you get in the mood."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  havingbettersexfeelmorepleasurewithmyself: {
    blurb:
      "When it comes to self-care, we often forget about the experience of pleasure. Research has identified countless health benefits to masturbation, including stimulating endorphins that combat pain and relieve stress, reducing bodily tension, improving sleep, raising self-esteem and body image, and strengthening pelvic and anal muscle tone. In addition, masturbation can help you figure out what things you like and what you’re comfortable with.",
    tips: [
      "Orgasming releases a mix of neurochemicals, like oxytocin, serotonin, vasopressin, and prolactin, that can help prepare you for sleep.",
      "Activities like yoga, gardening, and exercise that connect us to our senses and get us into our bodies are more likely to open us up to being sexual.",
      "Sex toys can be super effective in helping all types of bodies reach orgasm (note: this is not the only marker of pleasure)."
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  becomingmoreorganizedfeelmoreproductivedaily: {
    blurb:
      "Whether you're trying to learn a new skill, get up earlier, of check off tasks on your to-do list, you can mirror the psychology of video games as a tool to help you stay motivated and on task. Gamification uses game mechanics in a non-game context to reward you for completing tasks. Basically, when you complete a task, make sure you reward yourself with a hot cocoa or a TikTok break.",
    tips: [
      "Design a system that works for you! Is it a calendar, a desk organizer, or notes app?",
      "Put your work tasks in writing. It's helpful to have the end goal clear for reference.",
      "Explore what kind of spaces help you think more clearly...cafe, office, library, bed? Everyone works differently!"
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between two.",
    author: "christina"
  },
  becomingmoreorganizedfindclarityaroundmygoals: {
    blurb:
      "We’ve all heard the traditional benefits of being organized — such as its ability to help up feel more clear-headed — there are also health benefits. A study found being organized can have a positive input on what you’re eating. Specifically, people who worked in a neat space were two times as likely to pick an apple versus a chocolate bar when compared to those who were working in a messy work space.",
    tips: [
      "Get used to checklists! Once you have organized all the big stuff, checklists allow you to be more creative with the details.",
      "Put your work tasks in writing. It's helpful to have the end goal clear for reference.",
      "Explore what kind of spaces help you think more clearly...cafe, office, library, bed? Everyone works differently!"
    ],
    quote:
      "Our mind and body work together to create any type of change we are seeking. Continue to check in with BOTH! Lift off the weight of any judgements and expectations that you have about yourself, by creating a happy connection between the two.",
    author: "christina"
  },
  adoptingapetexercisemyheartmuscle: {
    blurb:
      "Pets can influence our emotional state, our sense of security and acceptance, and our outlook on life. Pets offer vital lessons about boundaries, connection, respect, and empathy. They need care and connection, activating us to be more empathetic and compassionate. In other words, pets help teach us how to trust and form healthy attachments.",
    tips: [
      "Adopting a pet — especially one in need — is a big step! Make sure you have the space, energy, time, and resources to take care of them in the long term.",
      "Spend time with your potential adoptee before you take them home to see how they interact with other animals and other humans.",
      "Be patient and establish a routine. You and your new buddy are still getting used to each other!"
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  adoptingapetsupportmymoodandemotionalhealth: {
    blurb:
      "Pets may provide emotional support, especially during times of stress. Some research shows that having a pet during an anxious event reduces the stress and emotional intensity of that event. Lower stress means lower blood pressure! One study even found that when people with hypertension adopted a pet from the shelter, their blood pressure declined significantly within five months.",
    tips: [
      "Adopting a pet — especially one in need — is a big step! Make sure you have the space, energy, time, and resources to take care of them in the long term.",
      "Spend time with your potential adoptee before you take them home to see how they interact with other animals and other humans.",
      "Be ready to spend a good chunk of change making sure your new friend is all up to date on vaccines, has healthy food, and toys."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  adoptingapettakeafirststeptowardsparenthood: {
    blurb:
      "There are stark differences between caring for an animal and raising a human, but the former can help you prepare for the latter. Having a pet gets you used to another life being dependent on you. More, a study shows that having a pet makes for healthier babies: increasing babies' beneficial gut microbes and reducing the risk of allergies and obesity.",
    tips: [
      "If you're planning on having a pet in the house with children, get an animal or breed that's known for being good with kids.",
      "You can learn a lot about patience from having a pet. Take time to reflect on what you observe.",
      "Just like having a child, it can help to find other people with pets to share tips and tricks with."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  expressingyourselffeelandbeheard: {
    blurb:
      "Trying new things can make us reflect on our old ideas and where they clash with our new knowledge, and inspire us to learn more and challenge comfirmation bias, our tendency to only seek out information we already agree with. Even in the short term, an uncomfortable experience can help us see old problems in a new light and brainstorm, and tackle the challenges we face with new energy.",
    tips: [
      "Play around with an artistic outlet that attracts you. Are you creative in writing, art, storytelling, or music?",
      "Creativity, like any skill, gets better as you work it! Steven King tries to write six pages every day. What's your goal?",
      "Consider a support group that features a creative outlet — maybe a writing class, improv group or knitting circle."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  expressingyourselffostercreativity: {
    blurb:
      "The science of creativity is evolving. We used to think that the left brain managed our analytical thinking while our right brain ruled over our imagination and creativity. We now know that creative thought doesn't come from a dedicated area of the brain. Creativity is likely a mix of genetics and experience—but studies point to more of the latter. Writing down new ideas, trying new things, and regularly learning can help foster creativity.",
    tips: [
      "Get comfortable making bad art. Rergardless of the medium, you likely won't be good at first, so be patient with yourself!",
      "Creativity, like any skill, gets better as you work it! Steven King tries to write six pages every day. What's your goal?",
      "Play around with an artistic outlet that attracts you. Are you creative in writing, art, storytelling, or music?"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  "journalinggetmoreclarityaboutmythoughts&feelings": {
    blurb:
      "Journaling is one of the best ways to clear your mind and get in touch with your thoughts and feelings. Writing down your feelings reduces activity in the amygdala, the brain's emotional center. This type of brain functioning can lessen the intensity of sadness, anger, and pain.",
    tips: [
      "Your journal is for you. It doesn't have to be an essay. You can write in bullet points, draw, or follow a structure that makes the most sense to you.",
      "Some people find it easier to journal when it calls to them, instead of doing it at a set time. Travel with a journal and pen.",
      "Journal about what upsets you. Be specific about the comments or actions that trigger feelings and how the feelings feel."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  journalingdocumentmylifeforfuturereflections: {
    blurb:
      "Ideas make the world go round, but it can be so hard to get your brain juices flowing. If you're feeling stuck sitting down with a pen and paper, there are other evidence-backed ways to get those ideas churning. Try journaling right before you go to bed – this is often a time where you're finally relaxed and not distracted, so your subconscious mind can really start to take over.",
    tips: [
      "If you are planning on re-reading your journal, make sure to give your future self the necessary context and details.",
      "Leave space in your entries for future you to comment and write down thoughts and reflections.",
      "Find a journal you enjoy! This will help keep you interested in writing in it."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  journalingreducestressbynoticingmyemotions: {
    blurb:
      "Journaling is one of the best ways to clear your mind and get in touch with your thoughts and feelings. It forces you to focus on your internal awareness of the present and process your experiences. With this focus, you can find clarity. Journaling can be a powerful tool to cope with anxiousness because it helps you identify fears and concerns that may be causing stress.",
    tips: [
      "Your journal is for you. It doesn't have to be an essay. You can write in bullet points, draw, or follow a structure that makes the most sense to you.",
      "Find a journal you enjoy! This will help keep you interested in writing in it.",
      "Journal about what upsets you. Be specific about the comments or actions that trigger feelings and how the feelings feel."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  findingacreativeoutletexpressmyselfinanewway: {
    blurb:
      "Art therapy is used with everyone from children to adults to groups and families to assess and treat anxiety, depression, substance abuse, addiction, relationship issues, and trauma. Its psychological benefits include building confidence, self-expression, clarity of thought, and empathy for others. Are you looking to do some art therapy yourself? Try writing, painting, quilting, or cooking — all are forms of self-expression!",
    tips: [
      "Start by acknowledging your emotions. That will help you have a strong base for explaining what and why this is important to you.",
      "Find out the why of why you want to express yourself differently. Is it an emotion, memory, current anxiety, or something else entirely?",
      "Find a space or group that hosts various events. Your local library, a community center, or a religious group can be a great place to start!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  findingacreativeoutletfeelinspireddaily: {
    blurb:
      "Have you ever noticed how solutions to problems tend to pop into your mind out of nowhere when you’re engaged in a hobby? Research shows that walking, in particular, boosts creative thinking. Albert Einstein is even thought to have come up with the theory of relativity while riding his bike!",
    tips: [
      "Surround yourself with things that keep you inspired! Pinterest can be a great place to start.",
      "Find mentors or people whose work inspires you. Spend time thinking about why.",
      "Try to inspire others. Think about what your younger self would have wanted to create. Now is a perfect time!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  findingacreativeoutletspendtimeonthingsthatbringmejoy: {
    blurb:
      "Creativity has positive effects on health. In a recent study, researchers provided almost forty people with art supplies and told them to create anything they wanted for 45 minutes. The scientists discovered that no matter the artistic experience of the participants, about 75% experienced a decrease in their cortisol levels, a hormone that the body secretes to respond to stress.",
    tips: [
      "After playing an instrument, the levels of the antibody immunoglobulin A are heightened helping your body fight viruses and bacteria.",
      "Find a space or group that hosts various events. Your local library, a community center, or a religious group can be a great place to start!",
      "Consider taking up an old hobby. You might find that it still sparks joy for you!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  drinkingmorewatersupportskinhealthfromtheinsideout: {
    blurb:
      "Chugging that 3L bottle isn't going to give you dewy skin — there is a lack of evidence proving that water consumption impacts skin hydration. Drinking water helps our bodies run optimally and deliver nutrients from the food we eat to our organs and tissues through blood flow. Once water has been absorbed into the bloodstream and filtered by the kidneys, it then begins to hydrate all the cells in your body, including your skin.",
    tips: [
      "Topical moisturizers can be much more effective than drinking water for the skin. Apply moisturizer soon after you wash to maximize absorption.",
      "If healthy skin is your goal, you'll need four things: sunscreen, a gentle everyday cleanser, a low-dose retinoid, and patience!",
      "Make sure to get in your orange fruits and veggies. Their beta-carotene supports healthy skin."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  drinkingmorewatersupportmywholehealth: {
    blurb:
      "Drinking water is amazing for your brain function. Brain cells are 80% water. For an average person, a 2% reduction in body water levels is enough to cause a 20% decrease in mental and physical performance.",
    tips: [
      "It is always good to increase your overall fluid per day. That includes anything you eat containing water, such as veggies and fruits.",
      "How do you know if you're hydrated? Look at your pee. It should be pale and clear.",
      "When you first start drinking more water, your body may hold onto it, leaving you to feel bloated. Be patient as your body adjusts."
    ],
    quote:
      "Take a deep breath and repeat to yourself, I have the power within me to make positive changes.",
    author: "christina"
  },
  volunteeringpushmybraintolearnnewskills: {
    blurb:
      "Every time you do something new, you change your brain. Specifically, you change the connections in it — this is called neuroplasticity. Learning new skills increases the density of the myelin in your brain, or the white matter that helps you improve performance on a variety of tasks, not just the new skill. As you perform that new task over and over again, the increased myelination increases the speed of all of the movements that make up that task.",
    tips: [
      "Volunteering to expand your skillset requires you to find an organization that has the patience to teach you. Take your time to find one!",
      "Make a list of exactly the skills you would like to learn. Then think about volunteering opportunities that will expose you to those skills.",
      "Don't let your skillset become the focus of why you volunteer. At the end of the day, it is to help others!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  volunteeringfeelofservice: {
    blurb:
      "Researchers have found when looking into the cognitive benefits of volunteering, volunteers noted a boost in their mental health and feelings of positivity from social contact, belonging to a group, and social support provision. One of the most common mental health issues that have come up in recent years is a dramatic increase in feelings of loneliness and isolation. Volunteering can leave you feeling personally rewarded and simultaneously emotionally fulfilled.",
    tips: [
      "Find a local organization that you admire, and take the time to get to know the people involved. Becoming a long-term helper is a great thing to do!",
      "Many charities have plenty of volunteers over the holidays, so take the time to think if you can become a long-term helper!",
      "Choose an activity you are comfortable or already accomplished at. This can make the biggest impact the fastest!"
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  "volunteeringconnectwithlike-mindedpeople": {
    blurb:
      "Studies have shown that volunteering helps people who donate their time feel more socially connected, warding off loneliness and depression. Volunteering has positive implications beyond mental health — a growing body of evidence suggests that giving your time to others may also result in better physical health, including lower blood pressure and longer lifespan. Some scientists believe that volunteering is helpful in stress reduction and often increases physical activity — both of which are strongly linked to positive physical health outcomes.",
    tips: [
      "Find a local organization that you admire, and take the time to get to know the people involved. Becoming a long-term helper is a great thing to do!",
      "Be patient when trying to make new friends! A lot of organizations have returning volunteers and you can become one, too, over time.",
      "Don't be afraid to stick around after or invite a group to coffee or drinks. Just because the volunteering is over doesn't mean you can't be friends."
    ],
    quote:
      "It is ok to take a few steps forward and even multiple steps backward in the journey towards reaching your goal! Be gentle with yourself, start small, find out what works for you, and what is realistic for your unique and special journey.",
    author: "christina"
  },
  travelingconnectwithmyroots: {
    blurb:
      "Taking time away from work and day-to-day routines can help to release the stress you've been holding onto allowing you to relax and recharge. And, stepping outside of your comfort zone and reconnecting with people and places from your lineage can give offer you a new perspective that leaves you invigorated and inspired.",
    tips: [
      "Why are your roots important to you? Is it because of your ancestors, stories about where you are from, or just for joy? It is important to know!",
      "See if you still have family where you are from. That can be a great way to ensure you get the truth and local culture, not just the tourist experience."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  travelinghavenewexperiences: {
    blurb:
      "Nature has fantastic benefits for your brain and body. Feeling the sun on your skin synthesizes vitamin D (great for heart health and lowering inflammation), and your brain tends to automatically relax in nature. The sounds and scenery can produce the same sort of neurological response that meditation and sleep can.",
    tips: [
      "Consider starting with a nation where you already speak the language! This can help when navigating public transport and restaurants.",
      "If leaving the country feels too daunting for your calendar or wallet, try visiting a new park instead. It can do wonders for your spirit and brain."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  travelingbroadenmyperspective: {
    blurb:
      "TTraveling may help you get out of your comfort zone; the behavioral space where your activities and behaviors fit a routine and pattern that minimizes stress and risk. Some researchers believe that leaving your comfort zone might mean you'll have an easier time dealing with new and unexpected changes. By taking risks in a controlled fashion, you can experience some of that uncertainty in a controlled, manageable environment.",
    tips: [
      "Are you interested in learning more about a particular subject? Cooking, art or architecture? There's a country known for your subject!",
      "Are you interested in learning more about a particular subject? Cooking, art or architecture? There's a country known for your subject!",
      "Check the State Department website for more information, like vaccine requirements or safety advisories."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  changingjobsmovetowardsmygoals: {
    blurb:
      "Switching jobs means new colleagues, new context, new challenges, and sometimes even, new industries or roles. All these changes will force you to learn new skills — and that means growth! While it can feel scary and exhausting to switch jobs, it can be a huge step in moving toward a bigger goal.",
    tips: [
      "Start by acknowledging your emotions and asking yourself why you want to make a change. This will help you have a strong base for explaining what and why this is important to you.",
      "Write down a list of companies and roles that interest you. Revamp your resume to include the skills and experiences companies are looking for in ideal candidates.",
      "Advance your skills and experience by taking a course online, enrolling in a certification program or exploring new projects or volunteer opportunities."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  },
  changingjobseasefinancialstress: {
    blurb:
      "Changing jobs may allow you to negotiate a higher salary or better benefits package, which can help to alleviate financial stress. Additionally, switching jobs may also provide you with new opportunities to advance your career, which could lead to increased earning potential over the long term. While it can feel scary and exhausting to switch things up, it can be a huge step in moving toward a bigger goal.",
    tips: [
      "Start by acknowledging your emotions and asking yourself why you want to make a change. This will help you have a strong base for explaining what and why this is important to you.",
      "Write down a list of companies and roles that interest you. Revamp your resume to include the skills and experiences companies are looking for in ideal candidates.",
      "Network: Whether it's a social event, a conference or an informational meeting, building connections with people will help you learn more about the roles and upcoming opportunities."
    ],
    quote: "You are worth it! Your dreams are worth the work, time and effort.",
    author: "julia"
  }
};

export const author: {
  [key: string]: { avatar: string; name: string; grad: string; position: string };
} = {
  christina: {
    avatar: christina,
    name: "Christina DuBois",
    grad: "LAc",
    position: "Acupuncturist"
  },
  devon: {
    avatar: devon,
    name: "Devon Klauck",
    grad: "WHNP",
    position: "Director of Clinical Product at Tia"
  },
  julia: {
    avatar: julia,
    name: "Julia Hunt",
    grad: "LCSW",
    position: "Director of Mental Health Services"
  }
};

export const playlistSection: {
  [key: string]: {
    barcode: ReactElement;
    description: string;
    keyword: ReactElement;
    icon1: ReactElement;
    icon2: ReactElement;
  };
} = {
  abundance: {
    barcode: <Abundance />,
    description:
      "Without sounding cliche: What does it mean to you to make the most of this year? This playlist is designed to help you D-I-Self-Health through uplifting and energizing songs that will hit different when you're in your flow. Because woman, you're on fire.",
    keyword: (
      <span>
        Abun- <br /> dance
      </span>
    ),
    icon1: <ManHead width='40' height='48' />,
    icon2: <Meditation width='55' height='44' />
  },
  closeness: {
    barcode: <Closeness />,
    description:
      "Without sounding cliche: What does it mean to you to make the most of this year? This playlist is designed to help you D-I-Self-Health through uplifting and energizing songs that will hit different when you're in your flow. Because woman, you're on fire.",
    keyword: (
      <span>
        Close- <br /> ness
      </span>
    ),
    icon1: <Heart width='40' height='40' />,
    icon2: <Coffee width='47' height='30' />
  },
  betterment: {
    barcode: <Betterment />,
    description:
      "Health isn't just one thing, it's everything that makes you feel strong, confident, and alive in your body. You know what that means to you. Think of this playlist as a soundtrack to feeling yourself--so turn it up, work it out, and raise a glass (of water) to your health.",
    keyword: (
      <span>
        Better- <br /> ment
      </span>
    ),
    icon1: <Up width='41' height='32' />,
    icon2: <Nap width='71' height='38' />
  },
  readiness: {
    barcode: <Readiness />,
    description:
      "Ready or not, here you come. We know planning for the future can feel overwhelming at times, so think of this playlist as your inspiration to start movin, grooving, and putting one step confidently in front of the other. Because fee-fii-fo-fum, look out baby here you come. ",
    keyword: (
      <span>
        Readi- <br /> ness
      </span>
    ),
    icon1: <Cloud width='47' height='27' />,
    icon2: <Bulb width='30' height='50' />
  }
};
